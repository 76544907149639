import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { ForgotPasswordResponse, Login, LoginResponse, UserLocalStorageData } from '../../types-enums/types/authentication';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EncryptDecrypt } from '../../utils/encrypt-decrypt';

@Injectable({
     providedIn: 'root',
})

export class AuthService {
     public isAuth = new BehaviorSubject<boolean>(false);

     constructor(
          private router: Router,
          private http: HttpClient
     ) {
          this.autoSignIn();
     }

     autoSignIn() {
          if (!localStorage.getItem('gvaSystem')) {
               this.isAuth.next(false);
               return of(null);
          }
          const userData: UserLocalStorageData = JSON.parse(EncryptDecrypt.decryptWithKey(localStorage.getItem('gvaSystem')));
          const expirationDate = new Date(userData.expirationDate);
          if (expirationDate <= new Date()) {
               this.isAuth.next(false);
               return of(null);
          } else {
               this.isAuth.next(true);
               return of(true);
          }
     }

     signIn(payLoad: Login): Observable<LoginResponse> {
          const url = environment.console + 'gva-login/'
          return this.http.post<LoginResponse>(url, payLoad).pipe(
               tap((response: LoginResponse) => {
               }),
               catchError(error => {
                    throw error;
               })
          );
     }

     resetPassword(params: HttpParams): Observable<ForgotPasswordResponse> {
          var url = environment.userManagement + 'forgot_password/';
          return this.http.get<ForgotPasswordResponse>(url, {params}).pipe(
               tap((data: ForgotPasswordResponse) => {
               }),
               catchError(error => {
                    throw error;
               })
          );
     }

     signOut() {
          localStorage.removeItem('gvaSystem');
          this.isAuth.next(false);
          this.router.navigate(['/']);
     }
}